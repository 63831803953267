var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-3"},[_c('h1',[_vm._v("illi-"+_vm._s(_vm.system.type)+"-"+_vm._s(_vm.system.number))]),(_vm.system.location)?_c('p',[_vm._v(_vm._s(_vm.system.location)),(_vm.system.department)?_c('span',[_vm._v(": "+_vm._s(_vm.system.department))]):_vm._e()]):_vm._e(),(this.system.online)?_c('p',[_c('v-icon',{attrs:{"small":"","color":(this.system.online) ? 'green' : 'red'}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v(" "+_vm._s(_vm.$t("systemMenu.online.isOnline"))+" "),_c('span',{staticClass:"v-list-item__subtitle",staticStyle:{"color":"rgba(0,0,0,.6)","margin-left":"10px","font-size":"0.8em"}},[_vm._v(_vm._s(_vm.$t("systemMenu.online.lastSeen", { date: _vm.lastOnlineText})))])],1):_vm._e(),(!this.system.online)?_c('p',[_c('v-icon',{attrs:{"small":"","color":(this.system.online) ? 'green' : 'red'}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v(" "+_vm._s(_vm.$t("systemMenu.online.isOffline"))+" "),_c('br'),_c('span',{staticClass:"v-list-item__subtitle",staticStyle:{"color":"rgba(0,0,0,.6)"}},[_vm._v(_vm._s(_vm.$t("systemMenu.online.lastSeen", { date: _vm.lastOnlineText})))])],1):_vm._e(),_c('v-list',[_c('v-list-item',{attrs:{"two-line":"","link":"","to":{
        name: 'residents',
      }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("systemMenu.manage_residents.title")))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t("systemMenu.manage_residents.description"))+" ")])],1)],1),_c('v-list-item',{attrs:{"two-line":"","link":"","to":{
        path: ("/systems/" + (_vm.system.id) + "/files")
    }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("systemMenu.manageFacilityFiles.title")))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t("systemMenu.manageFacilityFiles.description"))+" ")])],1)],1),_c('v-list-item',{attrs:{"two-line":"","link":"","to":{
        name: 'interests'
    }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("systemMenu.changeInterests.title")))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t("systemMenu.changeInterests.description"))+" ")])],1)],1),_c('v-list-item',{attrs:{"two-line":"","link":""},on:{"click":_vm.sendUpdateGeneral}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("systemMenu.syncGeneral.title")))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t("systemMenu.syncGeneral.description"))+" ")])],1),_c('v-list-item-action',[_c('v-list-item-action-text',[_vm._v(_vm._s(_vm.stateTextGeneral))]),(_vm.showGeneralStates)?_c('div',[_c('v-icon',{attrs:{"color":_vm.stateIconGeneralColor}},[_vm._v(" "+_vm._s(_vm.stateIconGeneral1)+" ")]),_c('v-icon',{attrs:{"color":_vm.stateIconGeneralColor}},[_vm._v(" "+_vm._s(_vm.stateIconGeneral2)+" ")]),_c('v-icon',{attrs:{"color":_vm.stateIconGeneralColor}},[_vm._v(" "+_vm._s(_vm.stateIconGeneral3)+" ")])],1):_vm._e()],1)],1),_c('v-list-item',{attrs:{"two-line":"","link":""},on:{"click":_vm.sendUpdatePrivate}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("systemMenu.syncPrivate.title")))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t("systemMenu.syncPrivate.description"))+" ")])],1),_c('v-list-item-action',[_c('v-list-item-action-text',[_vm._v(_vm._s(_vm.stateTextPrivate))]),(_vm.showPrivateStates)?_c('div',[_c('v-icon',{attrs:{"color":_vm.stateIconPrivateColor}},[_vm._v(" "+_vm._s(_vm.stateIconPrivate1)+" ")]),_c('v-icon',{attrs:{"color":_vm.stateIconPrivateColor}},[_vm._v(" "+_vm._s(_vm.stateIconPrivate2)+" ")]),_c('v-icon',{attrs:{"color":_vm.stateIconPrivateColor}},[_vm._v(" "+_vm._s(_vm.stateIconPrivate3)+" ")])],1):_vm._e()],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"two-line":"","link":"","to":{
        name: 'api_key'
    }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("systemMenu.api_key.title")))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t("systemMenu.api_key.description"))+" ")])],1)],1),_c('v-list-item',{attrs:{"two-line":"","link":""},on:{"click":_vm.confirmReboot}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("systemMenu.reboot.title")))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t("systemMenu.reboot.description"))+" ")])],1),(_vm.showRebootStatus)?_c('v-list-item-action',[_c('v-list-item-action-text',[_vm._v(_vm._s(_vm.stateTextReboot))]),_c('div',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.stateIconRebooting)+" ")])],1)],1):_vm._e()],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.showRebootConfirmation),callback:function ($$v) {_vm.showRebootConfirmation=$$v},expression:"showRebootConfirmation"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("systemMenu.reboot.confirmReboot.title"))+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("systemMenu.reboot.confirmReboot.description"))+" ")]),_vm._v("\\ "),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","color":"secondary"},on:{"click":function($event){_vm.showRebootConfirmation = false}}},[_vm._v(" "+_vm._s(_vm.$t("systemMenu.reboot.confirmReboot.cancel"))+" ")]),_c('v-btn',{attrs:{"dark":"","color":"primary"},on:{"click":function($event){_vm.sendReboot(); _vm.showRebootConfirmation = false}}},[_vm._v(" "+_vm._s(_vm.$t("systemMenu.reboot.confirmReboot.reboot"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }