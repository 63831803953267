<template>
  <v-card class="pa-3">
    <h1>illi-{{ system.type }}-{{ system.number }}</h1>
    <p v-if="system.location">{{ system.location }}<span v-if="system.department">: {{ system.department }}</span></p>
    <p v-if="this.system.online">
        <v-icon small :color="(this.system.online) ? 'green' : 'red'">mdi-checkbox-blank-circle</v-icon>
        {{ $t("systemMenu.online.isOnline") }}
        <span class="v-list-item__subtitle" style="color: rgba(0,0,0,.6); margin-left: 10px; font-size: 0.8em;">{{ $t("systemMenu.online.lastSeen", { date: lastOnlineText}) }}</span>
    </p>
    <p v-if="!this.system.online">
        <v-icon small :color="(this.system.online) ? 'green' : 'red'">mdi-checkbox-blank-circle</v-icon>
        {{ $t("systemMenu.online.isOffline") }}
        <br>
        <span class="v-list-item__subtitle" style="color: rgba(0,0,0,.6)">{{ $t("systemMenu.online.lastSeen", { date: lastOnlineText}) }}</span>
    </p>
    
    <v-list>

      <v-list-item
        two-line
        link
        :to="{
          name: 'residents',
        }"
      >
        <v-list-item-content>
          <v-list-item-title>{{
            $t("systemMenu.manage_residents.title")
          }}</v-list-item-title>
          <v-list-item-subtitle>
            {{ $t("systemMenu.manage_residents.description") }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item two-line link :to="{
          path: `/systems/${system.id}/files`
      }">
          <v-list-item-content>
              <v-list-item-title>{{ $t("systemMenu.manageFacilityFiles.title") }}</v-list-item-title>
              <v-list-item-subtitle>
                  {{ $t("systemMenu.manageFacilityFiles.description") }}
              </v-list-item-subtitle>
          </v-list-item-content>
      </v-list-item>
      
      <v-list-item two-line link :to="{
          name: 'interests'
      }">
          <v-list-item-content>
              <v-list-item-title>{{ $t("systemMenu.changeInterests.title") }}</v-list-item-title>
              <v-list-item-subtitle>
                  {{ $t("systemMenu.changeInterests.description") }}
              </v-list-item-subtitle>
          </v-list-item-content>
      </v-list-item>

      <v-list-item two-line link @click="sendUpdateGeneral">
        <v-list-item-content>
          <v-list-item-title>{{
            $t("systemMenu.syncGeneral.title")
          }}</v-list-item-title>
          <v-list-item-subtitle>
            {{ $t("systemMenu.syncGeneral.description") }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-list-item-action-text>{{
            stateTextGeneral
          }}</v-list-item-action-text>
          <div v-if="showGeneralStates">
            <v-icon :color="stateIconGeneralColor">
              {{ stateIconGeneral1 }}
            </v-icon>
            <v-icon :color="stateIconGeneralColor">
              {{ stateIconGeneral2 }}
            </v-icon>
            <v-icon :color="stateIconGeneralColor">
              {{ stateIconGeneral3 }}
            </v-icon>
          </div>
        </v-list-item-action>
      </v-list-item>

      <v-list-item two-line link @click="sendUpdatePrivate">
          <v-list-item-content>
              <v-list-item-title>{{ $t("systemMenu.syncPrivate.title") }}</v-list-item-title>
              <v-list-item-subtitle>
                  {{ $t("systemMenu.syncPrivate.description") }}
              </v-list-item-subtitle>
          </v-list-item-content>
          
          <v-list-item-action>
              <v-list-item-action-text>{{ stateTextPrivate }}</v-list-item-action-text>
              <div v-if="showPrivateStates">
                  <v-icon :color="stateIconPrivateColor">
                      {{ stateIconPrivate1 }}
                  </v-icon>
                  <v-icon :color="stateIconPrivateColor">
                      {{ stateIconPrivate2 }}
                  </v-icon>
                  <v-icon :color="stateIconPrivateColor"> 
                      {{ stateIconPrivate3 }}
                  </v-icon>
              </div>
          </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item two-line link :to="{
          name: 'api_key'
      }">
          <v-list-item-content>
              <v-list-item-title>{{ $t("systemMenu.api_key.title") }}</v-list-item-title>
              <v-list-item-subtitle>
                  {{ $t("systemMenu.api_key.description") }}
              </v-list-item-subtitle>
          </v-list-item-content>
      </v-list-item>
      <v-list-item two-line link @click="confirmReboot">
          <v-list-item-content>
              <v-list-item-title>{{ $t("systemMenu.reboot.title") }}</v-list-item-title>
              <v-list-item-subtitle>
                  {{ $t("systemMenu.reboot.description") }}
              </v-list-item-subtitle>
          </v-list-item-content>
          
          <v-list-item-action v-if="showRebootStatus">
              <v-list-item-action-text>{{ stateTextReboot }}</v-list-item-action-text>
              <div>
                  <v-icon>
                      {{ stateIconRebooting }}
                  </v-icon>
              </div>
          </v-list-item-action>
      </v-list-item>
    </v-list>


    <v-dialog
        v-model="showRebootConfirmation"
        width="500"
    >
        <v-card>
            <v-card-title>
                {{ $t("systemMenu.reboot.confirmReboot.title") }}
            </v-card-title>

            <v-card-text>
                {{ $t("systemMenu.reboot.confirmReboot.description") }}
            </v-card-text>\
            
            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    dark
                    color="secondary"
                    @click="showRebootConfirmation = false"
                >
                    {{ $t("systemMenu.reboot.confirmReboot.cancel") }}
                </v-btn>
                <v-btn
                    dark
                    color="primary"
                    @click="sendReboot(); showRebootConfirmation = false"
                >
                    {{ $t("systemMenu.reboot.confirmReboot.reboot") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

        <!-- <pre style="color: rgba(0,0,0,0.6); font-size: 0.5em;">{{system}}</pre> -->

  </v-card>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import {
  mapGetters,
  // mapActions
} from "vuex";
import { DateTime } from "luxon";
import { ILLI_API_SERVER_URL } from "../config";
export default Vue.extend({
  name: "systemMenu",
  title: (context) => `illi-${context.system.type}-${context.system.number}`,
  components: {
    //
  },
  data() {
    return {
      DateTime,
      selectedItem: 0,
      showPrivateStates: false,
      stateTextPrivate: "",
      stateIconPrivate1: "",
      stateIconPrivate2: "",
      stateIconPrivate3: "",
      stateIconPrivateColor: "black",
      showGeneralStates: false,
      stateTextGeneral: "",
      stateIconGeneral1: "",
      stateIconGeneral2: "",
      stateIconGeneral3: "",
      showRebootConfirmation: false,
      showRebootStatus: false,
      stateTextReboot: this.$t("systemMenu.reboot.stateTextReboot.rebooting"),
      stateIconRebooting: "mdi-loading mdi-spin",
      websocketGeneralReady: false,
      websocketPrivateReady: false,
    };
  },
  computed: {
    ...mapGetters({
      system: "system/system",
      user: "auth/user",
    }),
  },
  mounted() {
    this.stateTextPrivate = DateTime.fromISO(this.system.last_update_private).toLocaleString(DateTime.DATETIME_FULL);
    this.stateTextGeneral = DateTime.fromISO(this.system.last_update_general).toLocaleString(DateTime.DATETIME_FULL);
    this.lastOnlineText = DateTime.fromISO(this.system.last_online).toLocaleString(DateTime.DATETIME_FULL);
    
    if(this.system.update_general != 0) {
      this.openSocketForGeneral();
      this.showStatusGeneral(this.system.update_general);
    }

    if(this.system.update_private != 0) {
      this.openSocketForPrivate();
      this.showStatusPrivate(this.system.update_private);
    }
  },
  methods: {
    showStatusGeneral(status) {
      switch (status) {
        case -1: {
          this.showGeneralStates = false;
          break;
        }
        case 0:{
          this.showGeneralStates = true;
          this.stateIconGeneral1 = "mdi-loading mdi-spin";
          this.stateIconGeneral2 = "mdi-checkbox-blank-circle-outline";
          this.stateIconGeneral3 = "mdi-checkbox-blank-circle-outline";
          this.stateTextGeneral = this.$t("systemMenu.syncStatesTexts.starting");
          this.stateIconGeneralColor = "black";
          break;
        }
        case 1: {
          this.stateIconGeneral1 = "mdi-checkbox-blank-circle";
          this.stateIconGeneral2 = "mdi-loading mdi-spin";
          this.stateIconGeneral3 = "mdi-checkbox-blank-circle-outline";
          this.stateTextGeneral = this.$t("systemMenu.syncStatesTexts.requesting");
          this.stateIconGeneralColor = "black";
          break;
        }
        case 2: {
          this.stateIconGeneral1 = "mdi-checkbox-blank-circle";
          this.stateIconGeneral2 = "mdi-checkbox-blank-circle";
          this.stateIconGeneral3 = "mdi-loading mdi-spin";
          this.stateTextGeneral = this.$t("systemMenu.syncStatesTexts.syncing");
          this.stateIconGeneralColor = "black";
          break;
        }
        case 3: {
          this.stateIconGeneral1 = "mdi-checkbox-blank-circle";
          this.stateIconGeneral2 = "mdi-checkbox-blank-circle";
          this.stateIconGeneral3 = "mdi-check-circle";
          this.stateTextGeneral = this.$t("systemMenu.syncStatesTexts.done");
          this.stateIconGeneralColor = "green";
          break;
        }
        case 4: {
          this.stateIconGeneral1 = "mdi-alert-circle";
          this.stateIconGeneral2 = "mdi-checkbox-blank-circle-outline";
          this.stateIconGeneral3 = "mdi-checkbox-blank-circle-outline";
          this.stateTextGeneral = this.$t("systemMenu.syncStatesTexts.failed");
          this.stateIconGeneralColor = "red";
          break;
        }
      }
    },
    showStatusPrivate(status) {
      switch (status) {
        case -1: {
          this.showPrivateStates = false;
          break;
        }
        case 0:{
          this.showPrivateStates = true;
          this.stateIconPrivate1 = "mdi-loading mdi-spin";
          this.stateIconPrivate2 = "mdi-checkbox-blank-circle-outline";
          this.stateIconPrivate3 = "mdi-checkbox-blank-circle-outline";
          this.stateTextPrivate = this.$t("systemMenu.syncStatesTexts.starting");
          this.stateIconPrivateColor = "black";
          break;
        }
        case 1: {
          this.stateIconPrivate1 = "mdi-checkbox-blank-circle";
          this.stateIconPrivate2 = "mdi-loading mdi-spin";
          this.stateIconPrivate3 = "mdi-checkbox-blank-circle-outline";
          this.stateTextPrivate = this.$t("systemMenu.syncStatesTexts.requesting");
          this.stateIconPrivateColor = "black";
          break;
        }
        case 2: {
          this.stateIconPrivate1 = "mdi-checkbox-blank-circle";
          this.stateIconPrivate2 = "mdi-checkbox-blank-circle";
          this.stateIconPrivate3 = "mdi-loading mdi-spin";
          this.stateTextPrivate = this.$t("systemMenu.syncStatesTexts.syncing");
          this.stateIconPrivateColor = "black";
          break;
        }
        case 3: {
          this.stateIconPrivate1 = "mdi-checkbox-blank-circle";
          this.stateIconPrivate2 = "mdi-checkbox-blank-circle";
          this.stateIconPrivate3 = "mdi-check-circle";
          this.stateTextPrivate = this.$t("systemMenu.syncStatesTexts.done");
          this.stateIconPrivateColor = "green";
          break;
        }
        case 4: {
          this.stateIconPrivate1 = "mdi-alert-circle";
          this.stateIconPrivate2 = "mdi-checkbox-blank-circle-outline";
          this.stateIconPrivate3 = "mdi-checkbox-blank-circle-outline";
          this.stateTextPrivate = this.$t("systemMenu.syncStatesTexts.failed");
          this.stateIconPrivateColor = "red";
          break;
        }
      }
    },
    openSocketForGeneral() {
      return new Promise((resolve) => {
        this.showStatusGeneral(0)
        this.connection = new WebSocket("wss://platform.illi-tv.nl:5000/");

        this.connection.onopen = function () {
          this.connection.send(JSON.stringify({reason: "statusUpdateGeneral", system: this.system.id}));
        }.bind(this);

        this.connection.onmessage = function (event) {
          console.log(event);
          if (JSON.parse(event.data).status == "ready") {
            this.websocketGeneralReady = true;
            resolve(true);
          } else if (JSON.parse(event.data).status === 1) {
            this.showStatusGeneral(1);
          } else if (JSON.parse(event.data).status === 2) {
            this.showStatusGeneral(2);
          } else if (JSON.parse(event.data).status === 0) {
            this.showStatusGeneral(3);
          }
        }.bind(this);
      })
    },
    async sendUpdateGeneral() {
      if(!this.websocketGeneralReady) {
        await this.openSocketForGeneral();
      } 
      console.log("Sending update!");
      axios.put(`${ILLI_API_SERVER_URL}/system/updateGeneral/${this.system.id}`)
      .catch(({ response }) => {
        console.log(response);
        this.showStatusGeneral(4);
      });
    },
    openSocketForPrivate() {
      return new Promise((resolve) => {
        this.showStatusPrivate(0)

        this.connection = new WebSocket("wss://platform.illi-tv.nl:5000/");
        
        this.connection.onopen = function () {
          this.connection.send(JSON.stringify({reason: "statusUpdatePrivate", system: this.system.id}))
        }.bind(this)

        this.connection.onmessage = function (event) {
          console.log(event);
          if (JSON.parse(event.data).status == "ready") {
            this.websocketPrivateReady = true;
            resolve(true);
          } else if (JSON.parse(event.data).status === 1) {
            this.showStatusPrivate(1)
          } else if (JSON.parse(event.data).status === 2) {
            this.showStatusPrivate(2)
          } else if (JSON.parse(event.data).status === 0) {
            this.showStatusPrivate(3)
          }
        }.bind(this);
      })
    },
    async sendUpdatePrivate() {
      if(!this.websocketPrivateReady) {
        await this.openSocketForPrivate();
      }
      console.log("Sending update!");
      axios.put(`${ILLI_API_SERVER_URL}/system/updatePrivate/${this.system.id}`)
      .catch(({ response }) => {
        console.log(response);
        this.showStatusPrivate(4)
      });
    },
    sendReboot () {
        this.showRebootConfirmation = false;
        this.showRebootStatus = true;
        axios
        .put(`${ILLI_API_SERVER_URL}/system/reboot/`+this.system.id)
        .then(() => {
            
        })
        .catch(({response}) => {
            console.log(response)
            
        })
    },
    confirmReboot() {
      this.showRebootConfirmation = true;
    }
  }
})
</script>